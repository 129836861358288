.footer-container {
    width: 100%;
    background-color: rgb(40, 119, 40);
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.footer-copyright{
  color: white;
}