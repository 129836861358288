.contact-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    height: 100vh;
}

.contact-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
}

.contact-h2{
    margin-bottom: 10px;
    text-align: center;
}

.contact-a{
    color: black;
}

.contact-a:hover{
    cursor: pointer;
}

.contact-a:visited{
    color: black;
}

@media screen and (max-width: 768px){

}