.info-container{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 60px;
    gap: 40px;
}

.info-wrapper{
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-self: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
}

.info-h2{
    font-size: 40px;
    font-family: 'PT Sans', sans-serif;
}

.info-img{
    width: 100%;
    height: 450px;
    object-fit: cover;
    border-radius: 25px;
    box-shadow: 0 0 1rem 0.2rem rgba(0,0,0,0.2);
}

.info-p{
    font-size: 20px;
}

.info-a{
    color: blue;
}

.info-a:visited{
    color: blue;
}

.info-a:hover{
    cursor: pointer;
}

.video-video{
    object-fit: cover;
    width: 100%;
    height: 35%;
    max-width: 700px;
    border-radius: 25px;
    
}

.info-h3{
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.photos-wrapper{
    width: 100%;
    max-width: 1000px;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.photos-item{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-img{
    width: auto;
    max-width: 700px;
    max-height: 400px;
    border-radius: 25px;
    box-shadow: 0 0 1rem 0.2rem rgba(0,0,0,0.2);
}

.item-text{
    padding: 20px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}
@media screen and(min-width: 1000px){
    .info-container{
        width: 1300px;
    }
}

@media screen and (max-width: 768px){
    .info-p{
        font-size: 15px;
        margin-bottom: 20px;
    }
    .info-img{
        height: 400px;
    }
}

@media screen and (max-width: 670px){
    .info-h2{
        font-size: 20px;
    }
    .info-img{
        height: 200px;
    }
}