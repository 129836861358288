* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body{
  background: rgb(255, 255, 241);
  min-height: 100vh;
  font-family: 'PT Sans', sans-serif;
  position: relative;
}

.button-section{
  width: 100%;
  height: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#root{
  min-height: 100vh;
}

